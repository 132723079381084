 /* cores globais */
:root {
  --cor-primaria: #C4C4C4;
  --cor-secundaria: #C4C4C4;
  --cor-terciaria: #C4C4C4;
  --cor-rodape: #343A40;  
 
}

svg, svg path {
  fill: var(--cor-primaria);
  stroke: var(--cor-primaria);
}

.border-bottom {
  border-bottom: 1px solid #dee2e6!important;
}

.react-share__ShareButton svg, .react-share__ShareButton svg path {
  fill: #FFF;
  stroke: #FFF;
}

.gray-svg, .gray-svg path {
  fill: #575757;
  stroke: #575757;
} 

.jodit-container svg, .jodit-container svg path {
  fill: #4c4c4c;
  stroke: #4c4c4c;
}

.capitalize {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.vertical-start {
  min-height: 100%;
}

.modal-default::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-border-black {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.overflow-hidden {
  overflow: hidden;
}

.color-icon-menu {
  filter: invert(50%) sepia(0%) saturate(905%) hue-rotate(247deg)
    brightness(108%) contrast(83%);
}

.clickbutton:active {
  background-color: rebeccapurple;
}

.text-center-loja {
  text-align: "-moz-center";
  text-align: "-webkit-center";
}

.bg-container-mobioh {
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.hover-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* .select-list option:checked {
  background-color: red;
} */

input:focus {
  color: #495057 !important;
}